import { PageProps } from "gatsby";
import * as React from "react";
import Shell from "../../layout/shell";

const AntiSlavery: React.FC<PageProps> = (pageProps) => {
  return (
    <Shell>
      <div className="fixed-width">
        <h1>Modern slavery statement</h1>
      </div>
      <hr />
      <div className="fixed-width">
        <p className="general-copy">
          HOP has a zero-tolerance approach to all forms of modern-day slavery
          and human trafficking.
          <br />
          <br />
          A person is considered to be in slavery if they are:
          <br />
          <br />
          Forced to work through mental or physical threat;
          <br />
          <br />
          Owned or controlled by an ‘employer’ usually through mental or
          physical abuse or the threat of abuse;
          <br />
          <br />
          De-humanised and treated as a commodity or bought and sold as
          ‘property’; and/or
          <br />
          <br />
          Physically constrained or otherwise restricted from free movement
          against their will or with the constrainer’s knowledge and intent to
          enslave or traffic.
          <br />
          <br />
          Types of modern-day slavery include (but are not limited to):
          <br />
          <br />
          Forced labour – a person coerced to work under a threat of some sort
          of punishment either to themselves or to friends/ family
          <br />
          <br />
          Indentured or bonded labour- work provided to repay a debt (which is
          often based on travel, visas/ permits, or accommodation)
          <br />
          <br />
          Sexual exploitation – a person subject to sexual abuse and/or forced
          prostitution (forced marriage may also apply)
          <br />
          <br />
          Domestic servitude – a person obliged to provide services imposed by
          coercion, often within the home
          <br />
          <br />
          Human trafficking – a person arranges or facilitates the travel of
          another person into or within a situation of exploitation
          <br />
          <br />
          We are committed to ensuring that modern slavery is not taking place
          within any of our business relationships and to implementing and
          enforcing effective systems and controls aimed at ensuring that modern
          slavery is not taking place anywhere within our organisation or in any
          of our supply chains. We expect that our suppliers will hold their own
          suppliers to the same high standard.
          <br />
          <br />
          HOP will cooperate with law enforcement authorities to address any
          such instances that come to the attention of the company. Any concerns
          or issues related to slavery must be promptly reported using the
          speak-up policy. Where suppliers are not complicit in wrongdoing, HOP
          will work with organisations to support corrective actions and
          improvements to processes.
        </p>
      </div>
    </Shell>
  );
};

export default AntiSlavery;
